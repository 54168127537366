.calendar-admin {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  font-family: Arial, sans-serif;
  color: #333;

  .header {
    text-align: center;
    margin-bottom: 20px;
  }

  .location-selector-label {
    display: block;
    margin-bottom: 20px;
    font-weight: bold;

    .location-selector {
      display: block;
      width: 100%;
      padding: 8px;
      margin-top: 8px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }
  }

  .events-section {
    .events-header {
      font-size: 1.5em;
      margin-bottom: 20px;
      text-align: center;
    }

    .no-events-message {
      text-align: center;
      font-style: italic;
      margin-bottom: 20px;
    }

    .events-list {
      list-style-type: none;
      padding: 0;

      .event-item {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 10px;

        .event-field {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          flex: 1;

          label {
            font-weight: bold;
            margin-bottom: 5px;
          }

          .event-input {
            padding: 8px;
            border-radius: 4px;
            border: 1px solid #ccc;
          }

          .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
          }

          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;
            border-radius: 34px;
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
          }

          input:checked + .slider {
            background-color: #4CAF50;
          }

          input:checked + .slider:before {
            transform: translateX(26px);
          }

          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        }

        .remove-button {
          background-color: #ff4d4d;
          color: #fff;
          border: none;
          padding: 10px;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          margin-top: 10px;

          &:hover {
            background-color: #ff1a1a;
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .add-button, .save-button {
        flex: 1 1 48%;
        background-color: #4CAF50;
        color: white;
        padding: 15px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border: none;
        border-radius: 4px;

        &:hover {
          background-color: #45a049;
        }
      }

      .save-button {
        background-color: #008CBA;

        &:hover {
          background-color: #007B9A;
        }
      }
    }
  }
}

/* Media query for desktop screens */
@media (min-width: 768px) {
  .calendar-admin .events-section .events-list .event-item {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .event-field {
      flex-direction: row;
      align-items: center;
      flex: 1;
      margin-bottom: 0;

      label {
        margin-bottom: 0;
        margin-right: 10px;
      }

      .event-input {
        margin-right: 20px;
      }
    }

    .remove-button {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}
