.beer-container {
  background-color: black;
  color: white;
  padding: 20px 0;
	// width: 100%;
  // height: 100%;
	flex: 1 1 auto;
	
}

.beer-menu,
.specials-menu {
  font-family: Arial, sans-serif;
  max-width: 75%;
  margin: 0 auto;
}

.location-title,
.specials-title {
  font-size: 64px;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  text-transform: uppercase;
  font-family: "aller";
}

.location-subtitle {
  font-size: 28px;
  text-align: center;
  font-weight: thin;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: lightgray;
  font-style: italic;
  font-family: "aller";
}

.beer-list,
.specials-list {
  list-style-type: none;
  padding: 0;
}

.beer-item,
.special-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid white;
  height: 100%;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
}

.beer-info,
.special-info {
  display: flex;
  align-items: left;
  width: 100%;
  height: 100%;
}

.beer-name,
.special-name {
  font-size: 18px;
  font-weight: bold;
  max-width: 85%;
  margin: 0;
  padding-right: 10px;
}

.beer-abv,
.special-description {
  font-size: 14px;
  color: gray;
  font-weight: thin;
  margin: 0;
}

.beer-price,
.special-price {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
}

.error,
.loading {
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  color: red;
}

.specials-container {
  margin-top: 5em;
  margin-bottom: 5em;
}

// Media query for mobile devices
@media (max-width: 768px) {
  .location-title,
  .specials-title {
    font-size: 48px;
  }

  .location-subtitle {
    font-size: 20px;
  }

  .beer-name,
  .special-name {
    font-size: 16px;
    max-width: 100%; 
    padding-right: 0;
    flex: 2; // Allocate more width to the name
    text-align: left; // Left-justify the text
  }

  .beer-info,
  .special-info {
    flex-direction: column; 
  }

  .beer-abv,
  .special-description {
    font-size: 14px;
    display: block; 
    width: 100%; 
    text-align: left; 
    flex: 2; // Allocate more width to the description
  }

  .beer-price,
  .special-price {
    font-size: 14px;
    display: block; 
    width: 100%; 
    text-align: right; 
		margin-left: 1em;
    flex: 1; // Allocate less width to the price
  }

  .error,
  .loading {
    font-size: 14px;
  }
}
