/* src/pages/MembershipPage/index.scss */

@import '../../styles/thebunker.scss';

.membership-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;

  .title-container {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    max-height: 500px;
    overflow: hidden;
    bottom: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      display: block;
      object-fit: cover;
      background-position: bottom;
      position: relative;
      width: 100%;
      height: auto;
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      background: rgba(0, 0, 0, 0);
      color: white;
      font-family: 'aller';
      display: flex;
      align-items: center;
      align-content: center;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 0;
      margin: 0;
      padding: 0;

      h1.title {
        font-size: 3rem;
        margin: 0;
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    height: max-content;
    margin-inline: auto;
    gap: 15px;
    padding: 1em;
    max-width: 900px;

		.sign-up-btn {
			a {
				text-decoration: none;
				color: white;
			}
		}

		.section-title {
      font-size: 1.3rem;
      color: $primary;
      font-weight: bold;
			padding-bottom: 10px;
    }

    ul {
      padding: 4px 8px;
			padding-left: 15px;

    }

    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    hr {
      margin: 2rem 0;
    }

    .faqs {
      ul {
        padding: 0px;
        list-style-type: none !important;
      }
      .faq-question {
        font-weight: bold !important;
      }
      .faq-answer {
        font-weight: normal !important;
      }
    }



  }
}

@media (max-width: 992px) {
  .membership-container {
    font-size: smaller;

    .title-container .overlay h1.title {
      font-size: 2.5rem;
    }

    .main {
      padding: 0.5em;

      h1 {
        font-size: 1.75rem;
      }
    }
  }
}

/* src/pages/membershipPage/index.scss */

/* Existing styles... */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #28a745;
  }
}
