@import 'src/styles/thebunker.scss';

.leagues-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 100vh;
	height: 100%;
	margin-bottom: 3em;
  // height: 100vh;
	// overflow-y: scroll !important;

  .title-container {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    max-height: 500px;
    overflow: hidden;
    bottom: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      display: block;
      object-fit: cover;
      background-position: bottom;
      position: relative;
    }
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    font-family: 'aller';
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    margin: 0;
    padding: 0;

    img {
      object-fit: unset;
      object-fit: unset;
      object-position: center;
      display: block;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    height: max-content;
    margin-inline: auto;

    gap: 15px;
    padding: 1em;
    overflow: hidden;
    // margin: 2em;
  }
}
@media (max-width: 992px) {
  .leagues-container {
    font-size: smaller;
  }
}

.padding {
	margin: 2em !important;
}

.form {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
	background-color: lightgray;
	border-radius: 0.5em;
	display: grid;
	grid-template-columns: 1fr; // Default to full width on mobile
	gap: 20px;

	@media (min-width: 993px) { // Desktop and up
		grid-template-columns: repeat(2, 1fr); // Two columns for desktop
	}
}

.form-input {
	input, select, textarea {
		width: 100%;
		padding: 10px;
		border: 1px solid lightgray !important;
		border-radius: 4px;
		font-size: 1em;
		outline: none;

		&:focus {
			border-color: $primary !important;
		}
	}
	label {
		margin-bottom: 0.5em !important;
	}
}

.textarea {
	grid-column: 1 / -1; // This will make the textarea span the full width of the grid, regardless of the number of columns.
}

.submit {
	display: block;
	margin-top: 20px;
	width: fit-content;
	text-align: left;
}