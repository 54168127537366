.modal-backdrop {
  z-index: 3 !important;
}

.modal-backdrop.show {
  opacity: 0.75 !important;
}

// modal body full bg
// .modal div div {

//     border: none;

// }
