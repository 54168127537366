@import 'src/styles/thebunker.scss';

.course-teesheet-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  min-width: 80vw;
  // padding: 1em;
  border-color: white !important;
  border-width: 0px;

  iframe {
    width: '100%';
    height: '100%';
    min-height: 100vh;
    border-color: white !important;
    border-width: 0px;
  }
}

@media (max-width: 992px) {
  .course-teesheet-container {
    font-size: smaller;
  }
}
