.admin-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;

  select,
  input,
  button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}

h1 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}

.beer-table,
.specials-table { // Added styles for specials table
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

thead {
  background-color: #f2f2f2;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:hover {
  background-color: #f5f5f5;
}

input {
  width: 100%;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[name='name'],
input[name='description'] { // Added styles for description input
  width: 100%;
}

input[name='abv'],
input[name='price'],
input[name='priority'] {
  width: 50%;
}

button {
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
}

button.remove {
  background-color: #ff0000;
  color: #fff;
  width: auto;
  padding: 5px 10px;
  font-size: 12px;

  &:hover {
    background-color: #cc0000;
  }
}

button.add {
  background-color: #28a745;
  color: #fff;

  &:hover {
    background-color: #218838;
  }
}

button.save {
  background-color: #007bff;
  color: #fff;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
}

.beer-label {
  font-size: 12px;
  color: #666;
  width: 100%;
  display: block;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .beer-table,
  .specials-table { // Added styles for specials table in mobile view
    display: block;
    overflow-x: auto;
  }
}

.name-input,
.description-input { // Added styles for description input
  width: 100%;
}

.small-input {
  width: 50%;
}
