@import 'src/styles/thebunker.scss';

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
  align-content: center;
  height: 100%;
  font-size: 1.75em;

  .vertical-title {
    font-size: 15vw;
    text-align: left;
    line-height: 90%;
  }

  .rotate90 {
    transform: rotate(-90deg) translate(0px, 0px);
    height: fit-content;
  }

  .overlay {
    position: absolute;

    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: 'aller';

    img {
      object-fit: cover !important;
    }
  }
}

.carousel-item {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

a.carousel-control-next {
  display: none;
}
a.carousel-control-prev {
  display: none;
}

.carousel-inner {
  align-items: center;
  align-content: center;
  height: 100%;
  justify-content: center;
}

.news-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
  background-color: $primary;
}

.news-container ul {
  display: flex;
  list-style: none;
  margin: 0;
  // animation: scroll 25s infinite linear;
}

.news-container ul li {
  white-space: nowrap;
  padding: 10px 24px;
  color: #494949;
  position: relative;
  font-size: 8vh;
  color: white !important;
  // background-color: $light;
  // width: 100%;
  font-weight: 700;
  // font-family: monospace;
  text-align: left;
  color: black;
  text-transform: uppercase;
}

// @keyframes scroll {
//   from {
//     transform: translateX(1500px);
//   }

//   to {
//     transform: translateX(-100%);
//   }
// }

@keyframes scroll {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}
