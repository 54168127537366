@import 'src/styles/thebunker.scss';

.gift-container {
  display: flex;
  overflow-y: auto;
}

@media (max-width: 992px) {
  .gift-container {
    font-size: smaller;
  }
}
