@import 'src/styles/thebunker.scss';

@font-face {
  font-family: 'aller';
  src: local('Aller_Std_Bd'),
    url(./assets/fonts/Aller_Std_Bd.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'aller';
  src: local('Aller_Std_It'),
    url(./assets/fonts/Aller_Std_It.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'aller';
  src: local('Aller_Std_Lt'),
    url(./assets/fonts/Aller_Std_Lt.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'aller';
  src: local('Aller_Std_Rg'),
    url(./assets/fonts/Aller_Std_Rg.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

.bg-clear {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

.reviews-page {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 20px;

	.rating-container {
    transition: opacity 3s ease-in-out;
		
  }

  .thank-you-section,
  .feedback-section,
  .rating-section {
    opacity: 0;
    transition: opacity 3s ease-in-out;
  }

  .rating-container .thank-you-section,
  .rating-container .feedback-section,
  .rating-container .rating-section {
    opacity: 1;
  }
	
  .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
		border: 1px solid #ddd;
		border-radius: 8px;
		box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }

  .personalized-message p {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }

  .star-rating {
    display: flex;
		flex-direction: row;
    justify-content: center;
    margin: 20px 0;
  }

  .star-rating .star {
    cursor: pointer;
    transition: color 200ms;
  }

  .feedback-form {
    margin-top: 20px;
  }

  .feedback-form p {
    margin-bottom: 10px;
    font-size: 16px;
    color: #555;
  }

  .feedback-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
  }

  /* Additional styles for button if you add one */
  .feedback-form button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }

  .feedback-form button:hover {
    background-color: #45a049;
  }

  /* Style for the rating result message */
  .rating-result {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
  }
}

.router-locations {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 300px;
  margin-bottom: 25px;
}

.html,
body {
  overscroll-behavior: none !important;
}

.site-container {
  font-family: 'aller';
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  // overflow-y: auto;

  .footer {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
    padding: 1rem;
    background-color: $tertiary;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    p {
      margin: 0;
    }
  }
}

.overlay-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;

    background-image: linear-gradient(
      to top,
      rgb(0, 0, 0),
      rgba(0, 0, 0, 0.01)
    );
    z-index: 2;
    color: white;
    font-family: 'aller';
    text-align: center;
    display: flex;
    justify-content: center !important;

    p {
      align-self: flex-end;
      font-size: larger;
    }
  }
}

.lets-play {
  position: relative;
  margin: 0;
  padding: 0;

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);

    top: 0px;
    left: 0px;
    z-index: 2;
    color: white;
    font-family: 'aller';
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 992px) {
  .site-container {
    font-family: 'aller';
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: auto;
  }
}
