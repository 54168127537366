/* navigation.scss */
@import 'src/styles/thebunker.scss';

.dropdown-container {
  width: 100%;
  color: white;
	cursor: pointer;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.hover-text-primary {
  &:hover {
    color: $primary !important; /* Matches Bootstrap's primary color */
    cursor: pointer; /* Ensure cursor changes to pointer */
  }
}

.dropdown-arrow {
  font-size: 1.5rem;
  margin-left: 0.5rem;
  transition: transform 0.3s ease-in-out;

  &.rotate-180 {
    transform: rotate(180deg);
  }

  &:hover {
    cursor: pointer;
  }
}

.dropdown-content {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  color: white;

  &.show-content {
    max-height: 500px; /* Arbitrary value, enough to display all items */
  }

  &.hide-content {
    max-height: 0;
  }
}

.dropdown-link {
  display: block;
  padding: 0.5rem 0; /* Padding consistency */
  font-size: 1rem;
  color: white;
  transition: color 0.2s;

  &:hover {
    color: white !important; /* Matches Bootstrap's primary color */
    cursor: pointer;
  }
}

.text-secondary {
  color: #6c757d !important; /* Matches Bootstrap's secondary color */
}
