@import '../../styles/thebunker.scss'; // Import global styles if needed

.lesson-interest-form {
  .form-group {
    margin-bottom: 1rem;

    .form-label {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .form-control {
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 0.5rem;
      width: 100%;
    }

    .form-control:focus {
      border-color: #28a745;
      box-shadow: none;
    }

    select.form-control {
      appearance: none;
      background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5"><path fill="%23333" d="M2 0L0 2h4L2 0zM0 3l2 2 2-2H0z"/></svg>');
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 8px 10px;
    }

    textarea.form-control {
      resize: vertical;
    }
  }

  .text-danger {
    margin-bottom: 1rem;
  }

  button[type='submit'] {
    background-color: #28a745;
    border-color: #28a745;
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1rem;

    &:hover {
      background-color: #218838;
      border-color: #1e7e34;
    }

    &:disabled {
      opacity: 0.65;
    }
  }
}
