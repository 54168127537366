@import 'src/styles/thebunker.scss';

.shop-container {
  display: flex;
  overflow-y: auto;
}

@media (max-width: 992px) {
  .shop-container {
    font-size: smaller;
  }
}
