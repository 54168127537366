$danger: #F93154;
$success: #00B74A;
$dark: #262626;
$light: #dcdcdc;
$warning: #FFA900;
$black: #000;

$primary: #C12126;      // buttons, icons, H3 headers
$secondary: #8D2220;    // Links
$tertiary: #737373;     // Menu text on hover / selection



$theme-colors: (
  "danger": $danger,
  "success": $success,
  "primary": $primary,
  "primary-dark": $primary,
  "secondary": $secondary,
  "dark": $dark,
  "light": $light,
  "warning": $warning,
  "tertiary": $tertiary,
  "black": $black
);


.body {
    font-family: "aller"
}
//   src/assets/fonts/Aller_Std_Rg.ttf

@import "~bootstrap/scss/bootstrap.scss";


