.modal-body {
  max-width: 100% !important;
}

.pdf-document-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;

  transition: opacity 0.3s;
  opacity: 1;

	.menu-buttons {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
	}

  &.hidden {
    opacity: 0;
  }
}
