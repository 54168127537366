@import 'src/styles/thebunker.scss';

.menu-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  .title-container {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    max-height: 500px;
    overflow: hidden;
    bottom: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      display: block;
      object-fit: cover;
      background-position: bottom;
      position: relative;
    }
  }

  .section {
    margin: 1em;
    font-size: larger;
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: 'aller';
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    margin: 0;
    padding: 0;

    img {
      object-fit: unset;
      object-fit: unset;
      object-position: center;
      display: block;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // height: max-content;
    margin: 2em;
    // align-items: stretch;

    // gap: 15px;
    // padding: 1em;
    overflow: hidden;
    // margin: 2em;
  }
}

@media (max-width: 992px) {
  .menu-container {
    font-size: smaller;

    .main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      // height: max-content;
      margin: 2em;
      // align-items: stretch;

      // gap: 15px;
      // padding: 1em;
      overflow: hidden;
      // margin: 2em;
    }

    .menu-list-container h2 {
      font-size: large;
    }
    
    .section {
      font-size: small;
    }
  }
}
