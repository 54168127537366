@import 'src/styles/thebunker.scss';

.modal-dialog {
  position: relative;
  display: table;
  overflow: auto;
  width: auto;
  min-width: 300px;
}
.modal-body {
  /* Restrict Modal width to 90% */
  overflow-x: auto !important;
  max-width: 90vw !important;
}

.react-pdf__Page__canvas {
  padding-left: 1em !important;
  padding-right: 1em !important;
  width: 100% !important;
  height: 100% !important;
}

.pin {
  display: flex;
  justify-content: center;
  margin: 0;
  flex-direction: column;
  align-items: center;
  margin-top: -37.5px;
  width: 75px;
  height: 75px;
  margin-left: -37.5px;

  // padding: 0;

  strong {
    // font-size: x-large;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 1em;
    padding: 0.75em;
    margin-top: 0.5em;
    color: white;
  }

  img {
    // height: 78px;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 40px;
    // margin-top: -50px;
    // left: -26.5px;
  }
}

.gallery {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 15px;
  padding: 1em;
  overflow: hidden;

  .item-0 .item-1,
  .item-2,
  .item-3,
  .item-4 {
  }
}

.locations-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

// .amenities-container {
// 	margin-left: 2em;
//   margin-right: 2em;
// 	display: flex;
// 	width: 100%;
// }

.amenities-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; // Left justified on desktop

  @media (max-width: 767px) {
    // Adjust the breakpoint as needed
    justify-content: center; // Centered on mobile
  }
}

.amenity-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 130px;
  margin-bottom: 30px;
}

.location-details-container {
  display: grid;
  grid-column-gap: 2em;
  height: 100%;
  padding: 1em;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-left: 2em;
  margin-right: 2em;

  .item-0 {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .item-1 {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: 5;
    margin-left: 2em;
  }

  .item-2 {
    grid-row-start: 1;
    grid-column-start: 5;
    grid-column-end: 6;
  }

  .item-3 {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: 6;
  }
}

.rates {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1em;
  font-size: larger;
  max-height: 500px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  transition: background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;

  // Reset the color for <a> tags
  a {
    color: $primary;
    text-decoration: none; // Removes the underline from <a> tags
  }

  &:hover {
    // background-color: rgba(0, 0, 0, 0.1);
    color: $primary; // Sets a darker color on hover

    p {
      text-decoration: underline;
    }

    img,
    svg {
      filter: brightness(0.8); // Darkens the icon on hover
    }
  }

  img,
  svg {
    margin-bottom: 0.5rem;
    height: 30px;
    width: 30px;
  }

  p {
    font-size: 10px;
  }
}

.location-details {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.bottom-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-size: 56px;
  font-weight: bold;
  // background-color: rgba(0,0,0,0.5);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 1)
  );
  width: 100%;
  padding-left: 0.5em;
}

.gallery {
  .item-0 {
    grid-row-start: 1;
    grid-column-start: 1;

    grid-row-end: 3;
    grid-column-end: 4;

    position: relative;
    color: white;

    img {
      display: block;
      object-fit: cover;
      background-position: center;
      position: relative;
      height: 500px;
    }
  }
  .item-1 {
    grid-row-start: 1;
    grid-column-start: 4;

    grid-row-end: 2;
    grid-column-end: 5;

		img {
      height: 250px;
		}
  }
  .item-2 {
    grid-row-start: 1;
    grid-column-start: 5;

    grid-row-end: 2;
    grid-column-end: 6;
		img {
      height: 250px;
		}
  }
  .item-3 {
    grid-row-start: 2;
    grid-column-start: 5;

    grid-row-end: 3;
    grid-column-end: 6;
		img {
      height: 250px;
		}
  }
  .item-4 {
    grid-row-start: 2;
    grid-column-start: 4;

    grid-row-end: 3;
    grid-column-end: 5;
		img {
      height: 250px;
		}
  }
}




.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature__img {
  position: relative;
  text-align: center;
  color: white;
}

.location-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  .notice {
    // font-size: small;
  }

  .image-container {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    padding: 1em;
    padding-right: 0;
  }

  .title-container {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    max-height: 500px;
    overflow: hidden;
    bottom: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      display: block;
      object-fit: cover;
      background-position: bottom;
      position: relative;
    }
  }

  .bottom {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background: none;
    color: white;
    font-family: 'aller';
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    text-align: left;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 0;
    padding: 2em;
    padding-left: 3em;
    font-size: smaller;
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-family: 'aller';
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0;
    margin: 0;
    padding: 0;

    img {
      object-fit: unset;
      object-fit: unset;
      object-position: center;
      display: block;
    }
  }
}

@media (max-width: 992px) {
  .location-icons {
    font-size: 14px;

    button {
      width: 100%;
    }
  }

  .rates {
    font-size: large;
    justify-content: center;
    margin: 0;
    padding: 0;
    max-height: auto;
  }
  .modal-dialog {
    display: flex;
  }

  .gallery {
    display: flex;
    padding: 1em;
    width: 100%;
    overflow: hidden;

    .item-1,
    .item-2,
    .item-3,
    .item-4 {
      display: none;
    }
  }

  .bottom-left {
    bottom: 0px;
    left: 0px;
    font-size: xx-large;
    font-weight: bold;
    // background-color: rgba(0,0,0,0.5);
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 1)
    );
    // width: 100%;
    // height: auto;
    padding-left: 0.5em;
  }

  .location-container {
    .notice {
      font-size: x-small;
    }
  }

  .location-details-container {
    display: flex;
    flex-direction: column;
    grid-row-gap: 4em;
    height: 100%;
    width: 100%;
    padding: 1em;
    grid-template-rows: auto;
    grid-template-columns: auto;
    margin: 0;

    // div {
    //   width: 100%;
    // }

    .item-0 {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 1;
      width: 100%;
      // grid-row-end: 2;
    }

    .item-1 {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 6;

      margin-left: 0;
      // grid-row-start: 2;
      // grid-row-end: 3;
      // margin-left: 2em;
    }

    .item-2 {
      grid-row-start: 3;
      grid-column-start: 1;
      grid-column-end: 6;

      // grid-column-start: 5;
      // grid-column-end: 6;
    }

    .item-3 {
      grid-row-start: 2;
      grid-column-start: 1;

      grid-column-end: 6;
      // grid-column-start: 3;
      // grid-column-end: 6;
    }
  }
}

.grey {
  color: rgb(114, 111, 111);
}


#calendar .modal-content  {
		background-color: rgb(255, 255, 255) !important;
		
	}
