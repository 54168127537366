.ticker-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: grab;
  user-select: none;
	margin-top: 10px;
	margin-bottom: 10px;
}

.ticker-track {
  display: flex;
  white-space: nowrap;
  will-change: transform;
}

.ticker-item {
  flex: 0 0 auto;
  margin-right: 20px; // Adjust spacing between items
  min-width: 300px;   // Adjust based on your design
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 5px;
}

.grabbing {
  cursor: grabbing;
}

.event-description {
  font-size: 12px;
  color: grey;
}

.event-title {
  font-size: 14px;
  font-weight: bold;
}

.event-date {
  font-size: 12px;
}

.event-location {
  font-size: 12px;
}
