.rotated-carousel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

.carousel__item {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s;
}

.carousel__item--visible {
  opacity: 1;
}

.carousel__item img {
  max-height: 100%;
  object-fit: contain;
}


.rotated-carousel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

.carousel__item {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s;
}

.carousel__item--visible {
  opacity: 1;
}

.carousel__item img {
  max-height: 100%;
  object-fit: contain;
}
