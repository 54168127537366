@import '../../styles/thebunker.scss';

.calendar {
  font-family: 'aller', sans-serif;
  // background-color: black;
  // padding: 10px;
	// height: 700px;

  .calendar-navigation {
    background-color: $primary;
    color: $light;
    padding: 10px;

    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background-color: $secondary;
      color: $light;
      border: none;
      padding: 5px 10px;
      border-radius: 0.25rem;
      cursor: pointer;

      &:hover {
        background-color: darken($secondary, 10%);
      }
    }

    span {
      font-weight: bold;
    }
  }

	.calendar-header-day:nth-child(1),
.calendar-day:nth-child(7n + 1) {
  border-left: 1px solid $tertiary;
}


  .calendar-header,
  .calendar-body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid $tertiary;
      box-sizing: border-box;
    }
  }

  .calendar-header-day,
  .calendar-day {
    border-bottom: 1px solid $tertiary;
    border-right: 1px solid $tertiary;
    text-align: center;
    position: relative;

    &:first-child {
      border-left: 1px solid $tertiary;
    }
  }

  .calendar-header {
    .calendar-header-day {
      border-top: 1px solid $tertiary;
      border-bottom: none;
    }
  }

  .calendar-day {
    aspect-ratio: 1 / 1;
    min-width: 3rem;
    min-height: 3rem;
		overflow-y: scroll;

    &.not-current {
      background-color: #f0f0f0;
    }

    // &.today,
    &.end-of-week {
      border-right: 1px solid $tertiary;
    }


    &.today {
      background-color: lightgray !important;
      color: white;
			border-width: 1px solid $tertiary;
    }

		

    .calendar-day-label {
      position: absolute;
      top: 2px;
      right: 2px;
      font-size: 10px;
      font-weight: bold;
    }

    .event {
      font-size: 8px;
      margin: 5px 0;
      cursor: pointer;
      width: 100%;
      border: 1px $primary solid;
      background-color: $primary;
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }

	.expired-event {
		background-color: gray !important;
		text-decoration: line-through;
		border-color: gray !important;
	}
	

  .event-list {
    // background-color: rgba(0, 0, 0, 0.541);
    
    max-height: 550px;
    display: flex;
    flex-direction: column;
		overflow-y: scroll;

    // border-radius: 0.5rem;
    .event {
      border-bottom: 1px solid $tertiary;
      // border-radius: 0.25rem;
      margin-bottom: 10px;
      padding: 5px;
			font-size: 12px;

      // background-color: $light;

      .event-date {
        color: $tertiary;
        
      }

      .event-title {
        color: black !important;
        font-weight: bold;
      }
    }

    .no-events {
      text-align: center;
      padding: 20px;
      font-size: 1rem;
      color: $tertiary;
      // background-color: $primary;
      height: 100%;
    }
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
