@import 'src/styles/thebunker.scss';

.title {
  font-size: 5em;
  font-weight: bold;
  letter-spacing: 10px;
}



.subtitle {
  // font-size: 1.5em;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
  font-weight: 100;
  letter-spacing: 3px;
  font-size: 1.5em;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.see-locations {
  padding: 0.75em;
  border: 0;
  border-radius: 0.5em;
  padding-left: 2em;
  padding-right: 2em;
  font-weight: bold;
  text-align: center;
  margin: 0;
  background-color: $primary !important;
  color: white;
  margin-left: 35%;
  margin-right: 35%;
}

/* Video Container & Overlay */
.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
	background-color: black;
}
.videoContainer video {
  display: flex;
  height: 100%;
  min-height: 600px;
  max-height: 1000px;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
.videoContainer .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.25);
  color: white;
  font-family: 'aller';
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0;
  margin: 0;
  padding: 0;

//   .dropdown-menu {
//     display: flex;
//     flex-direction: column;
//     // background: $primary !important;
//     width: 400px !important;
//   }
// .dropdown-item {
//   border-bottom: 1px solid $light;
// }
//   .book-now-dropdown {
//     width: 400px;
//   }

  .overlay-msg {
    padding: 4em;
    border-radius: 1em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .desc {
      font-weight: bold;
      letter-spacing: 5px;
      // font-size: 1.6em;
    }
  }
}

.section-container {
  max-height: 400px;
}

.section-img {
  object-fit: cover;
  // height: 500px;
  width: 100%;
}

/* Trackman Section */
.trackman-title {
  padding: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    // font-size: 1.25rem;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 100;
    // font-size: 2.5rem;
    color: $primary;
  }
}

.trackmanContainer {
  display: flex;
  flex-direction: column;
  // background-color: white;

  .trackmanTitle {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    h1 {
      margin: 0;
      text-align: center;
      font-weight: bold;
      letter-spacing: 4px;
    }
  }

  .trackman-img-primary img {
    object-fit: cover;
    z-index: 1;
  }

  .trackman-desc-primary {
    justify-content: center;
    font-size: larger;
    letter-spacing: 2px;
    h2 {
      font-weight: 100;
      // font-size: 2em;
      color: $primary;
    }
  }
}

@media (max-width: 1400px) {
  .title {
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 10px;
  }

  .videoContainer {
    .desc {
      // font-size: large !important;
    }
  }

  .subtitle {
    // font-size: 1.5em;
    font-weight: 100;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 2em;
    font-weight: bold;
    letter-spacing: 10px;
  }

  .trackman-subtitle{
    font-size: 1.5em;
    // font-weight: 400;
  }

  .section-img {
    height: 300px;
  }

  .subtitle {
    font-size: 1em;
    font-weight: 100;
  }

  .see-locations {
    margin-left: 0;
    margin-right: 0;
  }

  .videoContainer .overlay {


    .desc {
      font-size: small !important;
    }
  }
}
