@import 'src/styles/thebunker.scss';

.form {
  display: grid;
  padding-block: 1em;
  width: 100%;
  gap: 1em;
  grid-template-columns: repeat(2, 1fr);

  .form-input {
    display: flex;
    flex-direction: column;

    input, textarea {
      border: 0px;
      border: 1px $dark solid;
      border-radius: 0.25em;
      font-size: larger;
      padding: 0.25em;
    }

    p {
      color: red !important;
      margin: 0;
    }

    label {
      margin: 0;
    }
  }

  .submit {
    font-size: larger;
    padding: 0.5em;
    border: 1px $primary solid;
    background-color: $primary;
    color: white;
    border-radius: 0.25em;
    margin-top: 1.5em;
  }
}

.grid-span-2 {
  grid-column: span 2;
}

@media (max-width: 992px) {
  .leagues-container {
    font-size: smaller;

    .form {
      grid-template-columns: 1fr;
    }

    .grid-span-2 {
      grid-column: span 1;
    }
  }
}
