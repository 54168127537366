/* src/pages/LessonsPage/index.scss */

@import '../../styles/thebunker.scss';

.lessons-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;

	.alert-wrapper {
		background-color: #ff7500;

		a {
			color: blue;
		}
	}

  .title-container {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    max-height: 500px;
    overflow: hidden;
    bottom: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      display: block;
      object-fit: cover;
      background-position: bottom;
      position: relative;
      width: 100%;
      height: auto;
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      font-family: 'aller';
      display: flex;
      align-items: center;
      align-content: center;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 0;
      margin: 0;
      padding: 0;

      h1.title {
        font-size: 3rem;
        margin: 0;
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    height: max-content;
    margin-inline: auto;
    gap: 15px;
    padding: 1em;
    max-width: 1200px;

    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      margin-left: 0.5rem;
    }

    hr {
      margin: 2rem 0;
    }

    .instructors-info,
    .lesson-rates,
    .junior-clinics {
      margin-bottom: 2rem;

      h2 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
      }

      h3 {
        font-size: 1.5rem;
      }

      p,
      ul {
        font-size: 1rem;
      }

      ul {
        list-style-type: disc;
        margin-left: 1.5rem;
      }

      .instructor {
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 992px) {
  .lessons-container {
    font-size: smaller;

    .title-container .overlay h1.title {
      font-size: 2.5rem;
    }

    .main {
      padding: 0.5em;

      h1 {
        font-size: 1.75rem;
      }

      .instructors-info,
      .lesson-rates,
      .junior-clinics {
        h2 {
          font-size: 1.5rem;
        }

        h3 {
          font-size: 1.25rem;
        }

        p,
        ul {
          font-size: 0.9rem;
        }
      }
    }
  }
}


/* src/pages/LessonsPage/index.scss */

/* Existing styles... */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  p {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #28a745;
  }
}
