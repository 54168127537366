@import 'src/styles/thebunker.scss';

.side-drawer-container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  width: 300px;
  // margin-right: 1em;
  overflow-y: visible;


  .side-drawer {
    background-color: black;
		display: flex;
    // top: 0;
    // position: fixed !important;
    min-height: 100vh;
    overflow-y: visible;

    z-index: 100;
    img {
      width: 250px;
      // height: 275px;
    }

    ul,
    li {
      list-style: none;
      font-size: x-large;
    }
  }
}

.side-drawer-mobile {
  display: none;
  text-align: center;
  background-color: black;
}

@media (max-width: 992px) {
  .side-drawer {
    display: none;
  }

  .side-drawer-mobile {
    display: flex;
		flex-direction: row;
    .navbar {
      background-color: black !important;
      display: flex;
      justify-content: space-between;
    }

    .navbar-toggler {
      background-color: white;
    }
    a {
      color: white;
    }
  }
}
