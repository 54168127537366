.admin-container {
  padding: 2rem;
  // background-color: lightgray;
  height: 100%;
	width: 100%;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.card-container {
  background-color: #ffffff;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.form-group {
  margin-bottom: 1rem;
}

.toggle-switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
